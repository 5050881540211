<template>
 <!-- 请求记录模块 -->
 <div
  v-loading.fullscreen.lock="loading"
  element-loading-text="请求数据中"
  element-loading-background="rgba(255, 255, 255, 0.5)"
 >
   <div class="append">
     <div class="seach"> 
        <el-select 
         clearable 
         class="el_select"
         placeholder="请选择店铺" 
         v-model="pageInfo.profileId" 
        >
          <el-option-group 
            :key="group.id"
            :label="group.name"
            v-for="group in shopList"
          >
            <el-option 
              v-for="item in group.marketPlaceTree"
              :key="item.profileId"
              :label="group.name+'-'+item.name"
              :value="item.profileId"
              @click.native="checkGroup(group.id, item.id)"
             >{{ item.name }}
            </el-option>
          </el-option-group>
        </el-select>
        <el-select 
         clearable 
         class="el_select"
         placeholder="请求结果" 
         v-model="pageInfo.requestResult" 
        >
          <el-option
            v-for="item in resultList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select 
          clearable 
          class="el_select"
          placeholder="选择时间" 
          v-model="pageInfo.timeType" 
        >
          <el-option
            v-for="item in TimeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <TimeQuantum
          style="margin-right:10px" 
          @selectFinish='topTime'
          section="近7天"
          @sectionFinish='sectionSure'
        ></TimeQuantum>
        <el-input style="width:360px;" v-model="keyword" placeholder="请选择">
          <el-select 
            clearable
            @clear="clearAsin"
            slot="prepend"
            v-model="selectContent" 
            placeholder="请选择" 
            style="width: 120px"
            >
             <el-option
              v-for="item in selectList"
              :key="item.id"
              :label="item.value"
              :value="item.id">
             </el-option>
          </el-select>
        </el-input>
        <el-button size="small" icon="el-icon-refresh" style="margin-left: 18px" @click="reset">重置</el-button>
        <el-button size="small" icon="el-icon-search" type="primary" @click="search">查询</el-button>
        <el-button size="small" icon="el-icon-download" type="primary" @click="downLoad">下载</el-button>
        <tableDialog :tableList="tableData" @setTableHead="setTableHead" style="margin-right:20px;float:right"></tableDialog>
        <el-table
          border
          style="width: 100%"
          :data="tableList"
          :selectable="selectable"
          @sort-change="sortTable"
          header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
        >
          <el-table-column label="订单号" align="center">
            <template slot-scope="scope">
              <el-link type="primary">{{scope.row.amazonOrderNumber}}</el-link>
            </template>
          </el-table-column>
          <el-table-column label="ASIN" align="cenetr">
           <template slot-scope="scope">
             <p
              v-for="(item,index) in scope.row.itemList"
              :key="index"
              style="text-align: center; height: 30px; line-height: 30px"
             >{{item.asin}}
             </p>
           </template>
          </el-table-column>
          <template v-for="item in tableHead">
            <el-table-column
              :key="item.prop"
              :prop="item.prop"
              :label="item.name"
              :sortable="item.sortable"
              header-align="center"
              align="center"
              :width="item.width"
              :formatter="item.formatter"
            ></el-table-column>
          </template>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[20, 30, 50, 100]"
          :total="total"
          :page-size="pageInfo.pageSize"
          :current-page="pageInfo.current">
        </el-pagination>
     </div>
   </div>
 </div>
</template>

<script>
import {
  pageInit,  // 初始化
  listPage,  // 查询分页
  exportExcel, // 导出Excel
}from '@/api/Mail/requestRecord.js'
import TimeQuantum from '@/components/TimeQuantum'
import tableDialog from '@/components/tableDialog.vue'
export default {
   components: { 
     TimeQuantum,   
     tableDialog,
   },
   data() {
     return {
       total: 0,
       loading: false, 
       pageInfo: {
         profileId: "",  // 店铺Id
         requestResult: "",  // 请求结果
         timeType: 0,  // 选择时间
         profileId: "",
         current: 1,
         pageSize: 20,
         startTime: "",
         endTime: "",
         sortType: "",
         sort: "",
       },
       tableData: [
         { name: "规则名称", prop: "ruleName", sortable: false },
         { name: "操作人", prop: "userName", sortable: false },
         { name: "店铺", prop: "shopName", sortable: false },
         { name: "索评结果", prop: "requestResultChinese", sortable: true },
         { name: "订购时间", prop: "purchaseDate", sortable: true },
         { name: "到货时间", prop: "arriveDate", sortable: false },
         { name: "发货时间", prop: "deliveryDate", sortable: true },
         { name: "创建时间", prop: "createTime", sortable: true },
         { name: "请求时间", prop: "requestTime", sortable: true },
       ],
       resultList: [
         { id: 0, name: "请求成功" },
         { id: 1, name: "请求中" },
         { id: 2, name: "请求失败" },
         { id: 3, name: "未请求" },
       ],
       TimeList: [
         { id: 0, name: "订购时间" },
         { id: 1, name: "发货时间" },
         { id: 2, name: "创建时间" },
         { id: 3, name: "请求时间" },
       ],
       selectList: [
         {id: 'orderAmazonNumber', value: '订单号'},
         {id: 'ruleName', value: '规则名称'},
         {id: 'asin', value: 'ASIN'},
       ],
       tableList: [],
       keyword: "",
       selectContent: "",
       tableHead: [],
       shopList: [],
       shopId: "",
       marketplaceId: "",
     }
   },
   created() {
     this.tableHead = this.tableData;
     this.getPageInit();
     setTimeout(()=> {
       this.search();
     }, 200)
   },
   methods: {
     // 重置事件
     reset() {
       this.keyword = "";
       this.selectContent = "";
       this.pageInfo.profileId = "";
       this.pageInfo.requestResult = "";
       this.pageInfo.timeType = "";
     },
     setTableHead(list) {
       this.tableHead = list;
     },
     getPageInit() {
       pageInit()
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
          this.shopList = res.data.data.shopList;
          res.data.data.shopList.forEach(item => {
            item.marketPlaceTree.forEach(data=> {
              if(data.isSelected == 1) {
                this.shopId = item.id;
                this.marketplaceId = data.id;
                this.pageInfo.profileId = data.profileId;
              }
            })
          });
         }
       })
     },
     checkGroup(parentId, subId) {
       this.shopId = parentId;
       this.marketplaceId = subId; 
     },
     topTime(val) {
       this.pageInfo.startTime = val.value[0];
       this.pageInfo.endTime = val.value[1];
     },
     search() {
       this.loading = true;
       // 组装请求参数 
       let TempData = "";
       if(this.selectContent == "" || this.keyword == "") {
         TempData = null;
       }else if(this.selectContent == "orderAmazonNumber") {
         TempData = { orderAmazonNumber: this.keyword }
       }else if(this.selectContent == "asin") {
         TempData = { asin: this.keyword }
       }else if(this.selectContent == "ruleName") {
         TempData = { ruleName: this.keyword }
       }
       let params = {   
         shopId: this.shopId,
         marketPlaceId: this.marketplaceId,
         requestResult: this.pageInfo.requestResult,
         timeType: this.pageInfo.timeType,
         beginTime: this.pageInfo.startTime,
         endTime: this.pageInfo.endTime,
         current: this.pageInfo.current,
         pageSize: this.pageInfo.pageSize,
         sortType: this.pageInfo.sortType,
         sort: this.pageInfo.sort
       }
       listPage({...TempData, ...params})
        .then(res=> {
          this.loading = false;
          this.tableList = [];
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.tableList = res.data.values;
            this.total = res.data.pageInfo.total;
          }
        })
     },
     // 排序(升序, 降序)  
     sortTable(column) {
       if(column.order == 'ascending') {
         this.pageInfo.sortType = 0;
       }else if(column.order == 'descending') {
         this.pageInfo.sortType = 1;
       }else if(column.order == null) {
         this.pageInfo.sort = '';
         this.pageInfo.sortType = '';
         this.search();
         return false;
       }
       if(column.prop == "requestResultChinese") {
         this.pageInfo.sort = "request_result";
       }else if(column.prop == "purchaseDate") {
         this.pageInfo.sort = "purchase_date";
       }else if(column.prop == "deliveryDate") {
         this.pageInfo.sort = "delivery_date";
       }else if(column.prop == "createTime") {
         this.pageInfo.sort = "create_time";
       }else if(column.prop == "requestTime") {
         this.pageInfo.sort = "request_time";
       }
       this.search();
     },
     // 下载事件 
     downLoad() {
       this.loading = true;
       let Temporary = "";
       if(this.selectContent == "" || this.keyword == "") {
         Temporary = null;
       }else if(this.selectContent == "orderAmazonNumber") {
         Temporary = { orderAmazonNumber: this.keyword }
       }else if(this.selectContent == "asin") {
         Temporary = { asin: this.keyword }
       }else if(this.selectContent == "ruleName") {
         Temporary = { ruleName: this.keyword }
       }
       let params = {   
         shopId: this.shopId,
         marketPlaceId: this.marketplaceId,
         requestResult: this.pageInfo.requestResult,
         timeType: this.pageInfo.timeType,
         beginTime: this.pageInfo.startTime,
         endTime: this.pageInfo.endTime,
         current: this.pageInfo.current,
         pageSize: this.pageInfo.pageSize
       }
       exportExcel({...Temporary, ...params})
       .then(res=> {
         this.loading = false;
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else if(res.data.code == 200) {
           const link = document.createElement('a');
           document.body.appendChild(link);
           link.href = res.data.data;
           link.click();
           document.body.removeChild(link);
           this.$message.success(res.data.message);
         }
       })
     },
   }
}
</script>

<style lang="scss" scoped>
  .append {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    .seach {
      width: 100%;
      .el-table {
        margin-top: 25px;
        margin-bottom: 50px;
      }
      .el_select {  
        margin-right: 20px;
      }
      .el-pagination {
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
</style>