import request from '@/utils/request'
// 请求记录
// 初始化查询

export function pageInit() {
  return request({
    url: "/api/amz/customer/pageInit",
    method: "get"
  })
}

// 查询分页
export function listPage(params) {
   return request({
     url: "/api/requestrecords/listPage", 
     params,  
     method: "get"
   })
}

// 导出Excel
export function exportExcel() {
  return request({
    url: "/api/requestrecords/exportExcel",
    method: "get"
  })
}